<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">

      <el-form-item label="名称">
        <el-input
            v-model="queryForm.name"
            placeholder="请输入名称"
            size="small"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="编码">
        <el-input
            v-model="queryForm.varNum"
            placeholder="请输入编码"
            size="small"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="变量值">
        <el-input
            v-model="queryForm.varValue"
            placeholder="请输入变量值"
            size="small"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="描述">
        <el-input
            v-model="queryForm.varDesc"
            placeholder="请输入描述"
            size="small"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
        <el-button icon="el-icon-plus" @click="addInfo" size="small"
        >新增
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        style="width: 100%"
    >
<!--      <el-table-column prop="id" label="主键"/>-->
      <el-table-column prop="name" label="名称"/>
      <el-table-column prop="varNum" label="编码"/>
      <el-table-column prop="varValue" label="变量值"/>
      <el-table-column prop="varDesc" label="描述"/>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-button icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)"
          >编辑
          </el-button
          >
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除系统变量['+scope.row.name+']吗?'"
              @confirm="handleDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button icon="el-icon-delete" size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
        v-model:currentPage="curPage"
        :page-sizes="[15, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

  <!--Variable新增、修改弹窗-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="30%"
      @close="handleClose"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="名称" prop="name" class="label-color">
        <el-input
            v-model="ruleForm.name"
            placeholder="请输入名称"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="编码" prop="varNum" class="label-color">
        <el-input
            v-model="ruleForm.varNum"
            placeholder="请输入编码"
            clearable
            :disabled="isInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="变量值" prop="varValue" class="label-color">
        <el-input
            v-model="ruleForm.varValue"
            placeholder="请输入变量值"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="varDesc" class="label-color">
        <el-input
            v-model="ruleForm.varDesc"
            placeholder="请输入描述"
            clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveInfo" v-if="isSave == true"
        >保存</el-button
        >
        <el-button type="primary" @click="editInfo" v-if="isSave == false"
        >修改</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import variableApi from "@/api/system/variable";
import {ref} from "vue";

export default {
  data() {
    return {
      queryForm: {
        name: "",
        varNum: "",
        varValue: "",
        varDesc: "",
        pageSize: 15,
        curPage: 1,
      },
      loading: false,
      total: 0,
      tableData: [],
      isSave: false,
      ruleForm: {
        name: "",
        varNum: "",
        varValue: "",
        varDesc: "",
      },
      rules: {

        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],

        varNum: [
          {
            required: true,
            message: "请输入编码",
            trigger: ["blur", "change"],
          },
        ],

        varValue: [
          {
            required: true,
            message: "请输入变量值",
            trigger: ["blur", "change"],
          },
        ],


      },
      dialogTitle: "",
      dialogVisible: false,
      isInput: true,
    };
  },
  mounted() {
    this.queryVariable();
  },
  methods: {
    clearQueryInput() {
      this.queryVariable();
    },
    submit() {
      this.queryForm.curPage = 1;
      this.queryVariable();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryVariable();
    },
    handleCurrentChange(val) {
      this.queryForm.curPage = val;
      this.queryVariable();
    },
    queryVariable() {
      variableApi.queryVariable(this.queryForm)
          .then((response) => {
            this.tableData = response.data.data.records;
            this.total = response.data.data.total;
          });
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.isSave = false;
      this.dialogTitle = "编辑系统变量信息";
      this.dialogVisible = true;
      this.ruleForm = Object.assign({},row);
    },
    addInfo() {
      this.isSave = true;
      this.dialogTitle = "新增系统变量";
      this.dialogVisible = true;
      this.isInput = false;
      // 重置表单
      this.ruleForm = {};
    },
    handleDelete(index, row) {
      variableApi.delVariable(row.id).then((response) => {
        if (response.data.code == 1) {
          this.$notify({
            title: response.data.msg,
            type: "success",
            offset: 40,
            duration: 2000,
          });
          this.tableData.splice(index, 1);
        } else {
          this.$notify.info({
            title: response.data.msg,
            type: "warning",
            offset: 40,
            duration: 3000,
          });
        }
      });
    },
    saveInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // 调用接口保存
          variableApi.addVariable(this.ruleForm).then((response) => {
            if (response.data.code == 1) {
              this.$notify({
                title: response.data.msg,
                type: "success",
                offset: 40,
                duration: 2000,
              });
              // 关闭窗口
              this.dialogVisible = false;
              // 刷新列表
              this.queryVariable();
            } else {
              this.$notify.info({
                title: "保存失败：" + response.data.msg,
                type: "warning",
                offset: 40,
                duration: 2000,
              });

            }
          });
        }
      });
    },
    editInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // 调用接口保存
          variableApi.editVariable(this.ruleForm).then((response) => {
            if (response.data.code == 1) {
              this.$notify({
                title: response.data.msg,
                type: "success",
                offset: 40,
                duration: 2000,
              });
              // 关闭窗口
              this.dialogVisible = false;
              // 重置表单
              // this.$refs['ruleForm'].resetFields();
              // 刷新列表
              this.queryVariable();
            } else {
              this.$notify.info({
                title: "修改失败：" + response.data.msg,
                type: "warning",
                offset: 40,
                duration: 2000,
              });

            }
          });
        }
      });
    },
    // 弹窗关闭事件
    handleClose() {
      this.ruleForm = {};
    },
  },
};
</script>

<style scoped>

.is-penultimate > .el-tree-node__content {
  color: red;
}
</style>